import {post} from '@/utils/axios'

// 演示
// export const login = (params) => post('/shida-oauth2server-1/oauth/token?grant_type=username_password&username='+ params.username +'&password='+ params.password +'&client_secret=sd-zs-qin-huang-dao&client_id=sd-zs-qin-huang-dao&isMiddleZs=是');
// 重新获取token
// export const getTokenAgain = params => post('/shida-oauth2server-1/oauth/token?grant_type=refresh_token&refresh_token='+ params.refresh_token +'&client_secret=sd-zs-qin-huang-dao&client_id=sd-zs-qin-huang-dao') 

// 本地
// export const login = (params) => post('/shida-oauth2server/oauth/token?grant_type=username_password&username='+ params.username +'&password='+ params.password +'&client_secret=sd-admin&client_id=sd-admin');
// 重新获取token
// export const getTokenAgain = params => post('/shida-oauth2server/oauth/token?grant_type=refresh_token&refresh_token='+ params.refresh_token +'&client_secret=sd-admin&client_id=sd-admin')

// 正式环境
export const login = (params) => post('/shida-oauth2server/oauth/token?grant_type=username_password&username='+ params.username +'&password='+ params.password +'&client_secret=sd-zs-qin-huang-dao&client_id=sd-zs-qin-huang-dao&isMiddleZs=是')
// 重新获取token
export const getTokenAgain = params => post('/shida-oauth2server/oauth/token?grant_type=refresh_token&refresh_token='+ params.refresh_token +'&client_secret=sd-zs-qin-huang-dao&client_id=sd-zs-qin-huang-dao')


export const logout = (params) => post('/shida-user-center/system/logout',params)

export const getRouters = (params) => post('/shida-user-center/SysMenu/getDataByUserId',params)

export const getUserinfo = (params) => post('/shida-user-center/SysUser/getUserInfo',params)

// 忘记密码
export const forgetPwd = (params) => post('/shida-user-center/SmsYzm/forgetPwd', params)

// 获取验证码
export const getSmsCheckCode = (params) => post('/shida-user-center/SmsYzm/getYzmIgnore', params)
