import axios from 'axios'
import store from '../store'
import router from '../router/index'
import { Base64 } from 'js-base64'
import {
    Message,MessageBox,Loading
} from 'element-ui'
import { getToken, setToken, removeToken } from '@/utils/auth'

let loadingInstance
// token是否已经提示过期的标识
let tokenExpiredPrompted = true

// 环境的切换
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = '/api'
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = process.env.VUE_APP_API
}
axios.defaults.timeout = 20000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'
// axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.common['role-distinction-client'] = 2

// // 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = store.state.user.token
        const userId = store.state.user.userId
        const userName = Base64.encode(store.state.user.name)
        const roleName = Base64.encode(store.state.user.roleName)
        const loginName = store.state.user.loginName
        const deptName = Base64.encode(store.state.user.deptName)
        token && (config.headers.Authorization = token)
        userId && (config.headers['x-userid-header'] = userId)
        userName && (config.headers['x-username-header'] = userName)
        roleName && (config.headers['x-role-header'] = roleName)
        loginName && (config.headers['x-loginname-header'] = loginName)
        deptName && (config.headers['x-district-header'] = deptName)
        loadingInstance = Loading.service({fullscreen:true});
        return config
    },
    error => {
        return Promise.error(error)
    })
//
// // 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            loadingInstance.close();
            return Promise.resolve(response)
        } else {
            loadingInstance.close();
            return Promise.reject(response)
        }
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: token过期
                // 清除token
                // 返回登录页面。
                case 401:
									if (tokenExpiredPrompted) {
										tokenExpiredPrompted = false
										MessageBox({
										    title: '系统提示',
										    showClose: true,
										    message: 'token过期或在另一台设备登录',
										    confirmButtonText: '重新登录',
										    type: 'warning'
										}).then(()=>{
										    removeToken()
										    store.state.user.token = ''
										    router.replace({
										        path: '/login',
										        query: {
										            redirect: router.currentRoute.fullPath
										        }
										    })
												 let timeout = setTimeout(() => {
														tokenExpiredPrompted = true
														clearTimeout(timeout)
												 }, 1500)
										}).catch(()=>{
										    removeToken()
										    store.state.user.token = ''
										    router.replace({
										        path: '/login',
										        query: {
										            redirect: router.currentRoute.fullPath
										        }
										    })
												let timeout = setTimeout(() => {
														tokenExpiredPrompted = true
														clearTimeout(timeout)
												}, 1500)
										})
									}
                    
                    break
                    // 403 无权限访问
                    // 登录过期对用户进行提示
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面
                case 403:
                    Message({
                        showClose: true,
                        message: '暂无权限，拒绝访问',
                        type: 'error'
                    })
                    break
                    // 404请求不存在
                case 404:
                    Message({
                        showClose: true,
                        message: '网络请求不存在',
                        type: 'error'
                    })
                    break 
                    // 其他错误，直接抛出错误提示
                default:
					if(error.response.config.responseType == 'blob') {
					    Message({
					        showClose: true,
					        message: decodeURIComponent(error.response.headers.errmsg),
					        type: 'error'
					    })
					}else {
					    Message({
					        showClose: true,
					        message: error.response.data.message,
					        type: 'error'
					    })
					}
            }
            loadingInstance.close();
            return Promise.reject(error.response)
        }
    }
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, type = 'json') {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
			 responseType: type
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, type = 'json') {
    return new Promise((resolve, reject) => {
        axios.post(url, params, { responseType: type })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}